import { action, observable, computed, toJS } from "mobx";
import { persist } from "mobx-persist";
import Fuse from "fuse.js/dist/fuse";
import { getMunicipalities, getMunicipality, pushMunicipality, pushImportUrbain, pushImportCrab } from "services/api";

const formatMunicipalities = (municipalities) => {
  return municipalities;
};

const optionsFuzzySearch = {
  // isCaseSensitive: false,
  // findAllMatches: false,
  // includeMatches: false,
  // includeScore: false,
  // useExtendedSearch: false,
  // minMatchCharLength: 1,
  // shouldSort: true,
  threshold: 0.1,
  // location: 0,
  // distance: 100,
  keys: ["name", "niscode"],
};

const doFuzzySearch = (list, value) => {
  let filteredMunicipalities = list;
  if (value !== "") {
    let fuse = new Fuse(list, optionsFuzzySearch);
    filteredMunicipalities = fuse.search(value);
  }
  return filteredMunicipalities;
};

class MunicipalityModel {
  @observable
  searchValue = "";

  @observable
  _municipalities = [];

  @observable
  activeMunicipality = null;

  @persist
  @observable
  rowsPerPage = 5;

  @action
  setMunicipalities = (value) => {
    this._municipalities = value;
  };

  @action
  setActiveMunicipality = (value) => {
    this.activeMunicipality = value;
  };

  @computed
  get filteredmunicipalities() {
    return doFuzzySearch(formatMunicipalities(this._municipalities), this.searchValue);
  }

  @computed
  get municipalities() {
    return this._municipalities;
  }

  @action
  loadMunicipalities = async () => {
    const result = await getMunicipalities();
    const formattedMunicipalities = [];
    result.forEach((municipality) => {
      let mun = municipality;
      if (municipality.startDate && municipality.endDate) {
        const startDate = new Date(municipality.startDate);
        const endDate = new Date(municipality.endDate);
        if (startDate < Date.now() && endDate > Date.now()) {
          mun.active = true;
        }
      } else {
        mun.active = false;
      }
      formattedMunicipalities.push(mun);
    });
    this.setMunicipalities(formattedMunicipalities);
  };

  @action
  loadMunicipality = async (munId) => {
    const result = await getMunicipality(munId);
    this.setActiveMunicipality(result);
  };

  @action
  saveMunicipality = async (munId, municipality) => {
    await pushMunicipality(munId, municipality);
    await this.loadMunicipality(munId);
  };

  @action
  importUrbainData = async (niscode, jaar, file) => {
    await pushImportUrbain(niscode, jaar, file);
  };

  @action
  importCrabAdressen = async (file, isCsv) => {
    await pushImportCrab(file, isCsv);
  };

  @action
  deleteMunicipality = async (munId) => {
    await removeMunicipality(munId);
    this.setActiveMunicipality([]);
    await this.loadMunicipalities();
  };

  @action
  setSearchValue = (value) => {
    this.searchValue = value;
  };

  @action
  setRowsPerPage = (value) => {
    this.rowsPerPage = value;
  };
}

export default MunicipalityModel;
